import React, { useState, useEffect } from 'react';
import { Link, Element } from 'react-scroll';
import './App.css';
import logo1 from './assets/logo1.png'; // Importar la imagen
import image1 from './assets/image1.png'; // Importar la imagen
import movil1 from './assets/movil1.png'; // Importar la imagen
import slide1 from './assets/slide1.png'; // Importar la imagen
import slide2 from './assets/slide2.png'; // Importar la imagen
import slide3 from './assets/slide3.jpg'; // Importar la imagen
import slide4 from './assets/slide4.jpg'; // Importar la imagen
import slide5 from './assets/slide5.png'; // Importar la imagen
import slide6 from './assets/slide6.png'; // Importar la imagen
import exito1 from './assets/exito1.png'; // Importar la imagen
import exito2 from './assets/exito2.png'; // Importar la imagen
import Navbar from './components/Navbar';
import { CookiesProvider } from './Context/CookiesContext.js'; // Importar el CookiesProvider
import CookieModal from './components/CookieModal.js';  // Importar el CookieModal
import { useCookies } from './Context/CookiesContext.js'; // Importar el useCookies (se usa en un componente después)
import videoBg from './assets/AI4Logic fondo web v2-HD.mp4'; // Importa el video (Asegúrate de que esté en la carpeta de assets)
import ReactGA from 'react-ga4';    // Importar Google Analytics

function MainApp() {
  const { cookies, isFirstVisit, cookiesAccepted } = useCookies();
  

  useEffect(() => {
    if (isFirstVisit) {
      console.log('Primera visita detectada, mostrando modal de cookies.');
    } else {
      console.log('Bienvenido de nuevo!');
    }
  }, [isFirstVisit]);

    useEffect(() => {
        // Reemplaza 'G-XXXXXXXXXX' con tu ID de medición
        ReactGA.initialize('G-R1SQXXDEJH');
        // Esto envía un evento de 'pageview' para la página de inicio
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);


  return (
    <div className="App">
      <CookieModal />
      <Navbar />
          <main className="App-main">

              {/*
           <Element name="vision-mision" className="App-section welcome">
            <div className="welcome-content">
              <div className="title-container">
                <h1 className="App-title">
                  <span className="text-orange">AI</span>
                  <span className="text-gray">4</span>
                  <span className="text-orange">Logic</span>
                </h1>
                <img src={logo1} alt="Logo" className="App-logo" />
              </div>
              <h3>
                <span className="text-orange">AI</span>
                <span className="text-gray">Think!</span>
              </h3>
            </div>
           </Element>
           */}

              {/* Sección de video en autoloop */}
              <Element name="video-section" className="App-section welcome">
                  <video autoPlay loop muted className="video-background">
                      <source src={videoBg} type="video/mp4" />
                      Tu navegador no soporta la reproducción de video.
                  </video>
              </Element>


              {/* Contenido que se muestra sobre el video */}
              <Element name="vision-mision" className="App-section welcome overlay">
                  <div className="welcome-content">
                        <h1 className="App-title">
                            <span className="text-orange">AI</span>
                            <span className="text-gray">4</span>
                            <span className="text-orange">Logic</span>
                        </h1>
                        <img src={logo1} alt="Logo" className="App-logo" />
                      {/*
                      <h3>
                          <span className="text-orange">AI</span>
                          <span className="text-gray">Think!</span>
                      </h3>
                      */}
                  </div>
              </Element>

          <Element name="vision-mision" className="App-section vision-mission">
            <div className="vision-mission-content">
              <h1 className="vision-mission-title"><span className="text-black">Visión y Misión</span></h1>
              <p className="vision-mission-text">
                <span className="text-black">Potenciar a los <b>Emprendedores</b> usando </span><span className="text-orange"><b>Nueva Lógica</b></span><br />
                <span className="text-black">con herramientas de </span><span className="text-orange"><b>Inteligencia Artificial</b></span>
              </p>
            </div>
          </Element>
          <Element name="mision" className="App-section mision">
            <div className="content">
              <h3 className="mision-title">Misión</h3>
              <p className="mision-text">El mundo empresarial es cada vez más competitivo. <br />La <span className="text-orange"><b>lógica</b></span> que usamos para identificar problemas y conseguir las soluciones es más compleja.</p>

              <div className="columns">
                <div className="column-left">
                  <h4>Emprendedor</h4>
                  <p>Debe pensar soluciones cada vez más eficaces y eficientes para su empresa, aportando su iniciativa y creatividad.</p>
                </div>
                <div className="column-right">
                  <h4>
                    <span className="text-orange">AI</span>
                    <span className="text-gray">4</span>
                    <span className="text-orange">Logic</span>
                  </h4>
                  <p>Propone herramientas que generan <span className="text-orange"><b>estrategias departamentales</b></span> como directores de área, además de otras más operativas que las ejecutan; esto es la <span className="text-orange"><b>Nueva Lógica</b></span>.</p>
                </div>
              </div>

              <div className="vision-mission-words">
                <div className="words-column">
                  <span className="text-gray"><b>Iniciativa</b></span>
                  <span className="text-gray"><b>Creatividad</b></span>
                </div>
                <div className="words-center">
                  <img src={logo1} alt="Logo" className="logo" />
                  <h2 className="App-subtitle">
                    <span className="text-orange">AI</span>
                    <span className="text-gray">Think!</span>
                  </h2>
                </div>
                <div className="words-column">
                  <span className="text-orange"><b>NUEVA LÓGICA DE</b></span>
                  <span className="text-orange"><b>PROCESOS EFICIENTES</b></span>
                </div>
              </div>
            </div>
          </Element>
          <Element name="imagen" className="image-slide">
            <img src={image1} alt="Imagen" className="responsive-image" />
          </Element>
          <Element name="4logicone" className="App-section logicone-slide">
            <div className="logicone-content">
              <h1 className="logicone-title">4LogicONE</h1>
              <h2 className="logicone-subtitle">Asistente  <span className="text-orange"><b>lógico</b></span> orientado a cliente.</h2>
              <p className="logicone-text">
                Permite que los usuarios puedan contactar con un asesor comercial,
                administrativo de pedidos, técnico postventa u otros perfiles.
              </p>
              <div className="image-container">
                <img src={movil1} alt="Móvil con la aplicación" className="logicone-image" />
              </div>
            </div>
          </Element>
          <Element name="informative-slides" className="App-section informative-slides">
            <div className="slide">
              <h3>Qué problema resuelve: La nueva Internet</h3>
              <p> Adelanta el camino a la futura Web 3.0, donde la interacción entre las empresas y sus clientes cambiará radicalmente mediante webs inteligentes, conociendo las particularidades de cada usuario y ofreciéndoles una solución personalizada.</p>
              <img src={slide1} alt="Problem Solving" className="slide-image" />
            </div>

            <div className="slide">
              <h3>Cómo lo hace: Entrenamiento.</h3>
              <p> De la misma manera que un empleado humano es formado y guiado cuando se incorpora a la compañía, 4LogicONE aprende de las instrucciones dadas, conoce los productos y servicios, la forma de atender corporativamente o lo que tiene que decir en cada caso para que la experiencia de cliente sea más que satisfactoria.</p>
              <img src={slide2} alt="How it Works" className="slide-image" />
            </div>

            <div className="slide">
              <h3>Cómo se integra: Web corporativa</h3>
              <p>Con un sencillo procedimiento en el entorno web, queda integrado.</p>
              <img src={slide3} alt="Integration" className="slide-image" />
            </div>

            <div className="slide">
              <h3>Cómo se revisa su trabajo: Supervisión</h3>
              <p>Con acceso a todas las conversaciones que han mantenido con los clientes, se estudian las respuestas dadas y la calidad de las mismas, afinando el entrenamiento con mejoras en las instrucciones dadas.</p>
              <img src={slide4} alt="Supervision" className="slide-image" />
            </div>

            <div className="slide">
              <h3>Datos: Privacidad</h3>
              <p>La privacidad es un derecho importante. Cumpliendo la normativa vigente, las conversaciones mantenidas solo pueden ser analizadas por el responsable designado y el propio usuario.</p>
              <img src={slide5} alt="Privacy" className="slide-image" />
            </div>

            <div className="slide">
              <h3>Ventajas</h3>
              <p>Atención 24h, múltiples idiomas, potencia ventas, conversación emocional, ahorra costes, proyecta imagen tecnológica.</p>
              <h6>Precio orientativo</h6>
              <p>500€ implantación - 250€/mes. Sin permanencia.</p>
              <img src={slide6} alt="Advantages" className="slide-image" />
            </div>
          </Element>

          <Element name="casos-de-exito" className="App-section success-cases">
            <div className="content">
              <h3 className="success-title">Casos de éxito</h3>

              {/*
              <div className="case">
                <p>
                  Adaptive Music Store es un e-commerce de música de librería para videojuegos. 4LogicONE explica a los clientes qué es la música adaptativa, conoce los productos que se venden, hace recomendaciones según las necesidades expresadas, ayuda en el proceso de compra, indicando los pasos y las diferentes formas de pago, indica las redes sociales de la compañía, etc.
                  (<a href="https://www.adaptivemusic.store/" target="_blank" rel="noopener noreferrer">https://www.adaptivemusic.store/</a>)
                </p>
                <img src={exito1} alt="Adaptive Music Store" className="case-image" />
              </div>
              */}

              <div className="case">
                <p>
                  Quarzo Apps es una web corporativa de aplicaciones móviles para dispositivos Android e iOS. 4LogicONE comenta a los clientes los juegos que existen, explica las reglas de cada juego, atiende las incidencias de jugadores, hace recomendaciones según las preferencias expresadas, etc.
                  (<a href="https://www.quarzoapps.com/" target="_blank" rel="noopener noreferrer">https://www.quarzoapps.com/</a>)
                </p>
                <img src={exito2} alt="Quarzo Apps" className="case-image" />
              </div>
            </div>
          </Element>
              <Element name="contacto" className="contact-section">

                  <h1 className="contact-title">Contacto</h1>
                  <p>Contacte con nosotros a través de nuestro asistente virtual 4LogicONE o envíe un email a: <a href="mailto:info@ai4logic.com">info@ai4logic.com</a>. Estaremos encantados de atenderle personalmente.</p>
            {/*
            <div className="contact-info">
              <div className="contact-grid">

                <p>Email: <a href="mailto:info@ai4logic.com">info@ai4logic.com</a></p>
                <p>Instagram: <a href="https://www.instagram.com/ai4logic/">@AI4Logic</a></p>
                <p>X / Twitter: <a href="https://x.com/AI4Logic">AI4Logic</a></p>
                <p>LinkedIn: <a href="https://www.linkedin.com/company/105143968">AI4Logic</a></p>
                <p>Facebook: <a href="https://www.facebook.com/profile.php?id=61566429579241">@AI4Logic</a></p>
                <p>YouTube: <a href="https://www.youtube.com/@AI4Logic">@AI4Logic</a></p>
              </div>
            </div>
            */}

                <h1 className="contact-title">Políticas</h1>
                  {/*   <p>Política de cookies: No utilizamos cookies.</p>*/}
            <div className="contact-content">
              <div className="contact-buttons">
                <a href="/assets/politica-privacidad.pdf" target="_blank" className="contact-button">Política de Privacidad</a>
                <a href="/assets/politica-cookies.pdf" target="_blank" className="contact-button">Política de Cookies</a>
                <a href="./assets/terminos-condiciones.pdf" target="_blank" className="contact-button">Términos y Condiciones</a>
              </div>
                  </div>
            {/*
            <div className="call-to-action">
              <h2>¿Interesado en mejorar tu negocio con nuestro chatbot?</h2>
              <p>¡Contáctanos hoy mismo y descubre cómo podemos ayudarte a transformar la manera en la que interactúas con tus clientes!</p>
              <a href="mailto:info@ai4logic.com" className="cta-button">Solicita más información</a>
            </div>
            */}
          </Element>
          
      </main>
    </div>
  );
}

function App() {
  return (
    <CookiesProvider> 
      <MainApp /> 
    </CookiesProvider>
  );
}

export default App;
