import React, { useState } from 'react';
import './Navbar.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import logo1 from '../assets/logo1.png'; // Importar la imagen
import { Link } from 'react-scroll'; // Importar Link de react-scroll

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="App-navbar">
            <div className="logo-container">
                <Link
                    to="welcome" // Nombre de la sección a la que redirigir
                    smooth={true} // Desplazamiento suave
                    duration={500} // Duración del desplazamiento
                    onClick={toggleMenu} // Cerrar el menú al hacer clic
                    className="logo-link" // Clase opcional para estilos
                >
                    <img src={logo1} alt="Logo" className="navbar-logo" />
                    <span>AI4Logic</span>
                </Link>
            </div>
            <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
                <ul>
                    <li>
                        <Link
                            to="vision-mision" // Nombre de la sección
                            smooth={true} // Desplazamiento suave
                            duration={500} // Duración del desplazamiento
                            onClick={toggleMenu} // Cerrar el menú al hacer clic
                        >
                            Visión y Misión
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="4logicone" // Nombre de la sección
                            smooth={true}
                            duration={500}
                            onClick={toggleMenu}
                        >
                            4LogicONE
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="contacto" // Nombre de la sección
                            smooth={true}
                            duration={500}
                            onClick={toggleMenu}
                        >
                            Contacto
                        </Link>
                    </li>
                    {/* Redes Sociales */}
                    <li>
                        <a
                            href="https://x.com/Ai4Logic"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={toggleMenu} // Cerrar el menú al hacer clic
                        >
                            <i className="fa-brands fa-x-twitter"></i>                        
                        </a>
                        <a
                            href="https://www.instagram.com/ai4logic/"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={toggleMenu} // Cerrar el menú al hacer clic
                        >
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a
                            href="https://www.linkedin.com/company/105143968"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={toggleMenu} // Cerrar el menú al hacer clic
                        >
                            <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                        <a
                            href="https://www.facebook.com/profile.php?id=61566429579241"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={toggleMenu} // Cerrar el menú al hacer clic
                        >
                            <i className="fa-brands fa-facebook-f"></i>
                        </a>
                        <a
                            href="https://www.youtube.com/@AI4Logic"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={toggleMenu} // Cerrar el menú al hacer clic
                        >
                            <i className="fa-brands fa-youtube"></i>
                        </a>

                    </li>
                </ul>
            </div>
            <div className="menu-icon" onClick={toggleMenu}>
                <i className={`fas fa-${isOpen ? 'times' : 'bars'}`}></i>
            </div>
        </nav>
    );
};

export default Navbar;
