import React, { useState } from 'react';
import { useCookies } from '../Context/CookiesContext';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, IconButton, Typography, styled, Checkbox, FormControlLabel,
  Tabs, Tab, Box, Paper
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import logo from '../assets/logo1.png';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#f5f5f5',
    margin: theme.spacing(2),
    maxWidth: '600px',
    width: '100%',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
  },
  '& .MuiDialogTitle-root': {
    backgroundColor: '#2196f3', // Azul material-ui
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  '& .MuiDialogContent-root': {
    backgroundColor: '#f5f5f5',
    color: 'black',
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
  },
}));

const TabPanel = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const CookieModal = () => {
  const { setCookie, isFirstVisit, cookiesAccepted } = useCookies();
  const [open, setOpen] = useState(false);
  const [showCustomize, setShowCustomize] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [preferences, setPreferences] = useState({
    essential: true,
    analytics: false
  });

  React.useEffect(() => {
    if (isFirstVisit && !cookiesAccepted) {
      setOpen(true);
    }
  }, [isFirstVisit, cookiesAccepted]);

  const handlePreferencesChange = (event) => {
    const { name, checked } = event.target;
    setPreferences((prev) => ({ ...prev, [name]: checked }));
  };

  const handleAcceptAll = () => {
    const allPreferences = {
      essential: true,
      analytics: true
    };
    setPreferences(allPreferences);
    Object.keys(allPreferences).forEach((key) => {
      setCookie(key, 'true', { expires: 365 });
    });
    setCookie('cookiesAccepted', 'true', { expires: 365 });
    setOpen(false);
  };

  const handleRejectAll = () => {
    const rejectedPreferences = {
      essential: true, // Siempre true
      analytics: false
    };
    setPreferences(rejectedPreferences);
    Object.keys(rejectedPreferences).forEach((key) => {
      setCookie(key, key === 'essential' ? 'true' : 'false', { expires: 365 });
    });
    setCookie('cookiesAccepted', 'false', { expires: 365 });
    setOpen(false);
  };

  const handleConfirmPreferences = () => {
    Object.keys(preferences).forEach((key) => {
      setCookie(key, preferences[key].toString(), { expires: 365 });
    });
    setCookie('cookiesAccepted', 'true', { expires: 365 });
    setOpen(false);
  };

  const handleCustomize = () => {
    setShowCustomize(true);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <StyledDialog 
      open={open} 
      maxWidth="md"
      fullWidth
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return; // Prevent closing when clicking outside
        }
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img 
            src={logo} 
            alt="Logo" 
            style={{ 
              height: '30px', 
              marginRight: '10px',
              filter: 'brightness(0) invert(1)'
            }} 
          />
          <Typography variant="h6">Política de Cookies</Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        {!showCustomize ? (
          <>
            <Typography variant="body1" gutterBottom>
              Utilizamos cookies para mejorar tu experiencia en nuestro sitio web. Las cookies estrictamente necesarias son esenciales para el funcionamiento del sitio, mientras que las cookies analíticas nos ayudan a mejorar. Puedes elegir aceptar todas las cookies, 
              rechazarlas (excepto las esenciales) o personalizarlas según tus preferencias.
            </Typography>
            <Typography variant="body2" sx={{ marginTop: 2, marginBottom: 2 }}>
              Para más información sobre cómo utilizamos las cookies, qué datos recopilamos y tus derechos, consulta nuestra{' '}
              <a
                href="/assets/politica-cookies.pdf"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#007BFF', textDecoration: 'underline' }}
              >
                Política de Cookies
              </a>
              {' '}y nuestra{' '}
              <a
                href="/assets/politica-privacidad.pdf"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#007BFF', textDecoration: 'underline' }}
              >
                Política de Privacidad
              </a>
              .
            </Typography>
            <Box sx={{ mt: 2, display: 'flex', gap: 2, justifyContent: 'center' }}>
              <Button variant="contained" color="primary" onClick={handleAcceptAll}>
                Aceptar todas
              </Button>
              <Button variant="outlined" color="primary" onClick={handleCustomize}>
                Personalizar
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleRejectAll}>
                Rechazar todas
              </Button>
            </Box>
          </>
        ) : (
          <Box sx={{ width: '100%' }}>
            <Paper elevation={0} sx={{ backgroundColor: 'transparent' }}>
              <Tabs 
                value={currentTab} 
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Estrictamente necesarias" />
                <Tab label="Analíticas" />
              </Tabs>
            </Paper>

            <TabPanel value={currentTab} index={0}>
              <Typography variant="h6" gutterBottom>Cookies estrictamente necesarias</Typography>
              <Typography variant="body2" gutterBottom>
                Estas cookies son esenciales para el funcionamiento del sitio web y no pueden ser desactivadas. No almacenan ninguna información personal identificable y son necesarias para funcionalidades básicas como la navegación y el acceso a áreas seguras del sitio web. Duración: sesión/365 días.
              </Typography>
              <FormControlLabel
                control={<Checkbox checked={true} disabled />}
                label="Habilitadas (requerido)"
              />
            </TabPanel>

            <TabPanel value={currentTab} index={1}>
              <Typography variant="h6" gutterBottom>Cookies Analíticas</Typography>
              <Typography variant="body2" gutterBottom>
                Estas cookies nos permiten medir y analizar el uso del sitio web, de forma completamente anónima, para mejorar tu experiencia. Utilizamos esta información para entender cómo interactúan los usuarios con nuestro sitio y realizar mejoras. No identificamos personalmente a ningún usuario. Duración: 365 días.
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    name="analytics"
                    checked={preferences.analytics}
                    onChange={handlePreferencesChange}
                  />
                }
                label="Permitir cookies analíticas"
              />
            </TabPanel>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        {showCustomize && (
          <>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button variant="outlined" color="primary" onClick={handleAcceptAll}>
                Aceptar todas
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleRejectAll}>
                Rechazar todas
              </Button>
            </Box>
            <Button variant="contained" color="primary" onClick={handleConfirmPreferences}>
              Confirmar mis preferencias
            </Button>
          </>
        )}
      </DialogActions>
    </StyledDialog>
  );
};

export default CookieModal;
